.Navbar {

  .links {
    z-index: 1;
    font-size: 17px;
    a {
      color: inherit;
      &:hover {
        opacity: 0.8;
      }

      &:not(:first-of-type) {
        margin-left: 20px;
      }
    }
  }
}