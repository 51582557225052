.AuthFooter {
  font-size: 0.9rem;
  a {
    margin: 0 0.5rem;
  }
}

.Content {
  margin: 10px 0 15px 0;
}
