.Row {
  margin: 10px 0 10px 0;
}

.Label {
  position: absolute;
  margin-left: -240px;
  margin-top: 7px;
  text-align: right;
  width: 220px;
}

.Check {
  color: #28a745;
  position: relative;
  float: right;
  margin-right: -25px;
  margin-top: -30px;
}
